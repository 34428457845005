import { SubMenu } from 'components/SubMenu';
import React from 'react';
import { projectsRoutes } from 'routing';
import { NavigationOptions, ModeOptions } from 'types/select-types';

interface Props {
  id: string;
  view?: ModeOptions;
}
interface Items {
  label: NavigationOptions;
  href: string;
}

export const ProjectNavigation: React.FC<Props> = ({ id, view }) => {
  const items: Items[] = [];

  switch (view) {
    case 'dashboard':
      items.push(
        {
          label: 'Overview',
          href: projectsRoutes.PROJECT_OVERVIEW(id, view),
        },
        {
          label: 'Quicklinks',
          href: projectsRoutes.PROJECT_QUICKLINKS(id, view),
        },
        {
          label: 'Info',
          href: projectsRoutes.PROJECT_REPORTS(id, view),
        },
      );
      break;
    case 'allocation':
      items.push(
        {
          label: 'Overview',
          href: projectsRoutes.PROJECT_OVERVIEW(id, view),
        },
        {
          label: 'Details',
          href: projectsRoutes.PROJECT_DETAILS(id, view),
        },
      );
      break;
    case 'budget':
      items.push(
        {
          label: 'Overview',
          href: projectsRoutes.PROJECT_OVERVIEW(id, view),
        },
        {
          label: 'Details',
          href: projectsRoutes.PROJECT_DETAILS(id, view),
        },
        {
          label: 'Invoices',
          href: projectsRoutes.PROJECT_INVOICES(id, 'budget'),
        },
      );
      break;
    case 'pm':
      items.push(
        {
          label: 'Roadmap',
          href: projectsRoutes.PROJECT_OVERVIEW(id, view),
        },
        {
          label: 'Backlog',
          href: projectsRoutes.PROJECT_DETAILS(id, view),
        },
        {
          label: 'Board',
          href: projectsRoutes.PROJECT_INVOICES(id, view),
        },
      );
      break;
    default:
      items.push(
        {
          label: 'Overview',
          href: projectsRoutes.PROJECT_OVERVIEW(id, 'dashboard'),
        },
        {
          label: 'Details',
          href: projectsRoutes.PROJECT_DETAILS(id, 'dashboard'),
        },
        {
          label: 'Reports',
          href: projectsRoutes.PROJECT_REPORTS(id, 'dashboard'),
        },
        {
          label: 'Invoices',
          href: projectsRoutes.PROJECT_INVOICES(id, 'Dashboard'),
        },
        {
          label: 'Quicklinks',
          href: projectsRoutes.PROJECT_QUICKLINKS(id, 'dashboard'),
        },
      );
  }

  return <SubMenu items={items} />;
};
