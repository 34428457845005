import { ModeOptions } from 'types/select-types';

interface Routes {
  [key: string]: string | (() => string);
}

export const getRoutesValues = (routes: Routes): string[] => {
  return Object.values(routes).map((r) => ('function' === typeof r ? r() : r));
};

export const authRoutes = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CHECK_EMAIL: '/check-email',
  RESET_PASSWORD: '/reset-password',
  SING_UP: '/sing-up',
  REGISTER_PHONE: '/register-phone',
  VERIFY_PHONE: '/verify-phone',
  VERIFY_EMAIL: '/verify-email',
  PASSWORD_CHANGED: '/password-changed',
  GOOGLE: '/authenticate/google',
  VERIFY_INVITE: '/verify-invite',
};

export const dashboardRoutes = {
  DASHBOARD: '/dashboard',
};

export const timeRoutes = {
  MY_TIMESHEET: '/my-timesheet',
  TEAM_TIMESHEET: '/team-timesheet',
  MY_SCHEDULE: '/my-schedule',
  TEAM_SCHEDULE: '/team-schedule',
  COMPANY_CALENDAR: '/company-calendar',
  MY_CALENDAR: '/my-calendar',
  MY_TIME_OFF: '/my-time-off',
  TEAM_CALENDARS: '/team-calendars',
  TEAM_TIME_OFF: '/team-time-off',
};

export const companiesRoutes = {
  COMPANIES: '/companies',
  CLIENTS: '/clients',
  CLIENT_VIEW: (id: string = ':id') => `/clients/${id}`,
  CLIENT_DETAIL: (id: string = ':id') => `/clients/${id}/details`,
  AGENCY_RESELLERS: '/agency-resellers',
  AGENCY_RESELLER_VIEW: (id: string = ':id') => `/agency-resellers/${id}`,
  AGENCY_RESELLER_DETAIL: (id: string = ':id') => `/agency-resellers/${id}/details`,
  AGENCY_TALENT_PARTNERS: '/agency-talent-partners',
  SALES_REP_COMPANIES: '/sales-rep-companies',
};

export const contactsRoutes = {
  CONTACTS: '/contacts',
  USERS: '/users',
  TEAMS: '/teams',
  APPLICANTS_RECRUITS: '/applicants-recruits',
  SALES_TEAM: '/sales-team',
};

export const projectsRoutes = {
  PROJECTS_LIST: '/projects',
  PROJECT_OVERVIEW: (id: string = ':id', view: string = ':view') => `/projects/${id}/${view}`,
  PROJECT_DETAILS: (id: string = ':id', view: string = ':view') => `/projects/${id}/${view}/details`,
  PROJECT_REPORTS: (id: string = ':id', view: string = ':view') => `/projects/${id}/${view}/reports`,
  PROJECT_INVOICES: (id: string = ':id', view: string = ':view') => `/projects/${id}/${view}/invoices`,
  PROJECT_QUICKLINKS: (id: string = ':id', view: string = ':view') => `/projects/${id}/${view}/quicklinks`,
  PENDING: '/pending',
  PROPOSALS: '/proposals',
  REQUESTS: '/REQUESTS',
};

export const expensesRoutes = {
  MY_EXPENSES: '/my-expenses',
  TEAM_EXPENSES: '/team-expenses',
};

export const referralsRoutes = {
  REFERRED_USERS: '/referred-users',
  COMMISSIONS: '/commissions',
  PAYMENT_OPTIONS: '/payment-options',
  EXPECTED_COMMISSIONS: '/expected-commissions',
  PROMO_SALES_COLLATERAL: '/promo-sales-collateral',
};

export const billingRoutes = {
  BILLING: '/billing',
  MY_PAYMENTS: '/my-payments',
  TEAM_PAYMENTS: '/team-payments',
  SALES_REP_PAYMENTS: '/sales-rep-payments',
};

export const documentsRoutes = {
  TEAM_HANDBOOK: '/team-handbook',
  BRAND_GUIDELINES: '/brand-guidelines',
  SALES_COLLATERAL: '/sales-collateral',
};

export const globalSettingsRoutes = {
  USER_GROUPS: '/user-groups',
  DEPARTMENTS: '/departments',
  PROJECT_ROLES: '/project-roles',
  EXPENSE_CATEGORIES: '/expense-categories',
};
