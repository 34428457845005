import { computed, observable, action } from 'mobx';
import { ToastStore } from 'store/toast-store';
import { RouterStore } from 'mobx-react-router';
import { ProjectsService } from 'store/services/projects-service';
import {
  ProjectDetailsQL_projectDetails_project,
  ProjectDetailsQL_projectDetails_team,
  ProjectDetailsQL_projectDetails_details,
  ProjectDetailsQLVariables,
} from 'types/gql-generated/ProjectDetailsQL';

export class ProjectDetailsStore {
  private projectsService: ProjectsService;
  private toastStore: ToastStore;
  @observable private routerStore: RouterStore;
  @observable private _team: ProjectDetailsQL_projectDetails_team[] = [];
  @observable private _details: ProjectDetailsQL_projectDetails_details[] = [];
  @observable private _project: ProjectDetailsQL_projectDetails_project = {
    id: '',
    name: '',
    clientId: '',
    clientName: '',
    agencyId: '',
    agencyName: '',
  };

  constructor(projectsService: ProjectsService, routerStore: RouterStore, toastStore: ToastStore) {
    this.projectsService = projectsService;
    this.routerStore = routerStore;
    this.toastStore = toastStore;
  }

  get projectId() {
    return 1;
  }

  @computed
  get teamDetails() {
    // Get a list of the periods from the start to the end date of the range returned in the details.
    const periodRange = this._details.map((detail) => detail.period);

    // Data for each of the team members for the each period.
    const periodTeamDetails = periodRange.map((period) => {
      const detailsForPeriod = this._details.find((detail) => detail.period === period);

      const teamDetails = detailsForPeriod?.team;

      return teamDetails;
    });

    // Combine team member identity with their details.
    const combinedTeamDetails = this._team.map((member) => {
      // This is the team member's details for all of the current periods
      const memberDetails = periodTeamDetails.map((detail) => detail?.find((detail) => detail.userId === member.id));

      let costsForMember: any = {};
      let hoursForMember: any = {};

      memberDetails.map((memberDetail, index) => {
        const formattedPeriod = this.formatPeriod(memberDetail?.period || periodRange[index]);

        costsForMember[formattedPeriod] = memberDetail?.costUsd;
        hoursForMember[formattedPeriod] = memberDetail?.costTaasHours;
      });

      return {
        name: member.firstName + ' ' + member.lastName,
        title: member.teamRole,
        costs: {
          ...costsForMember,
        },
        hours: {
          ...hoursForMember,
        },
      };
    });

    return { combinedTeamDetails };
  }

  @computed
  get creditsOverview() {
    let overviewCosts: any = {};

    this._details.map((detail) => {
      const formattedPeriod = this.formatPeriod(detail?.period);

      overviewCosts[formattedPeriod] = this.formatCurrency(detail?.credits);
    });

    return overviewCosts;
  }

  @computed
  get endBalanceOverview() {
    let endBalanceCosts: any = {};

    this._details.map((detail) => {
      const formattedPeriod = this.formatPeriod(detail?.period);

      endBalanceCosts[formattedPeriod] = this.formatCurrency(detail?.endBalance);
    });

    return endBalanceCosts;
  }

  @computed
  get billableExpensesOverview() {
    let billableExpensesCosts: any = {};

    this._details.map((detail) => {
      const formattedPeriod = this.formatPeriod(detail?.period);

      billableExpensesCosts[formattedPeriod] = this.formatCurrency(detail?.billableExpensesUsd);
    });

    return billableExpensesCosts;
  }

  @computed
  get expensesDetails() {
    // Get a list of the periods from the start to the end date of the range returned in the details.
    const periodRange = this._details.map((detail) => detail.period);

    // Data for each of the expense type for each period. So this is an array of periods that have an array of expense types.
    const expensesDetailsByPeriod = periodRange.map((period) => {
      const detailsForPeriod = this._details.find((detail) => detail.period === period);

      const expenseDetails = detailsForPeriod?.expenses;

      return expenseDetails;
    });

    // Get all of the expense names across all periods
    // @ts-ignore
    const expenseTypes = [...new Set(expensesDetailsByPeriod.flat())];
    const expenseNames = expenseTypes.map((expense) => {
      const temp = expense?.expensesCategoryName;
      return temp;
    });

    // Get the expenses associated with each name in each period.
    const combinedExpensesDetails = expenseNames.map((expense) => {
      // This is the team member's details for all of the current periods
      const expenseDetails = expensesDetailsByPeriod.map((expenseDetail) =>
        expenseDetail?.find((detail) => detail.expensesCategoryName === expense),
      );

      let costsForExpense: any = {};

      expenseDetails.map((expenseDetail, index) => {
        const formattedPeriod = this.formatPeriod(expenseDetail?.period || periodRange[index]);

        costsForExpense[formattedPeriod] = expenseDetail?.billableExpensesUsd;
      });

      return {
        name: expense,
        costs: {
          ...costsForExpense,
        },
      };
    });

    return combinedExpensesDetails;
  }

  @computed
  get netIncomeOverview() {
    let netIncomeCosts: any = {};

    this._details.map((detail) => {
      const formattedPeriod = this.formatPeriod(detail?.period);

      netIncomeCosts[formattedPeriod] = this.formatCurrency(detail?.netCashMovement);
    });

    return netIncomeCosts;
  }

  @computed
  get startingBalanceOverview() {
    let startingBalanceCosts: any = {};

    this._details.map((detail) => {
      const formattedPeriod = this.formatPeriod(detail?.period);

      startingBalanceCosts[formattedPeriod] = this.formatCurrency(detail?.startingBalance);
    });

    return startingBalanceCosts;
  }

  @action
  loadProjectDetails = async (input: ProjectDetailsQLVariables['input']) => {
    const { team, project, details } = await this.projectsService.loadProjectDetails(input);
    this._team = team;
    this._project = project;
    this._details = details;
  };

  @action
  getMoreHours = () => {
    this.toastStore.showSuccessMessage('More hours reguested');
  };

  private formatPeriod(period?: string | null) {
    if (period === undefined || period === null) {
      period = '';
    }
    // This funky date stuff is to make up for JavaScript being weird about dates being in YYYY-MM format. It sets the date to one day before the beginning of the month, so we do this to keep things at the right date.
    return new Date(new Date(period).setDate(new Date(period).getDate() + 1)).toLocaleDateString('en', {
      month: 'short',
      year: 'numeric',
    });
  }

  private formatCurrency(amount?: number | null) {
    if (amount === undefined || amount === null) {
      amount = 0;
    }

    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
}
