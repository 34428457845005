import React, { useState } from 'react';
import cn from 'classnames';
import { SelectOption } from 'types/select-types';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';
import { ViewAs } from '../ViewAs';
import './TableToolbar.scss';
import styles from './TableToolbar.module.scss';
import { DateGrouping } from 'types/gql-generated';
import { getPeriodByView } from 'store/timeline-store/timeline-utils';

interface Props {
  className?: string;
  goPrev: () => { start: Date; end: Date };
  goNext: () => { start: Date; end: Date };
  goToday: () => void;
  onViewChange: (view: DateGrouping) => void;
  period: any;
  periodTitle: string;
  selectedView: DateGrouping;
  options: SelectOption<DateGrouping>[];
}

export const TableToolbar: React.FC<Props> = observer((props) => {
  const [buttonText, setButtonText] = useState<string>('This Month');
  const [periodLabel, setPeriodLabel] = useState<string>(
    props.period.start.toLocaleDateString() + '- ' + props.period.end.toLocaleDateString(),
  );

  const onViewChange = ({ value }: any) => {
    const currentPeroid = getPeriodByView(value);

    props.onViewChange(value);
    if (value === 'MONTH') {
      setButtonText('This Month');
      setPeriodLabel(currentPeroid.start.toLocaleDateString() + '- ' + currentPeroid.end.toLocaleDateString());
    }
    if (value === 'WORK_WEEK') {
      setButtonText('This Week');
      setPeriodLabel(currentPeroid.start.toLocaleDateString() + '- ' + currentPeroid.end.toLocaleDateString());
    }
    if (value === 'DAY') {
      setButtonText('Today');
      setPeriodLabel(currentPeroid.start.toLocaleDateString() || '');
    }
  };

  return (
    <div className={cn('d-flex', 'justify-content-between')}>
      <div className={cn(styles.navigation, props.className)}>
        <ViewAs />
      </div>
      <div className={cn('d-flex', 'justify-content-end', styles.navigation, props.className)}>
        <Button className={styles.btn_today} color="default" onClick={props.goToday}>
          {buttonText}
        </Button>
        <div className={`ml-ch-6 ${styles.btns_set_right}`}>
          <Button
            className={`icon-prev ${styles.button}`}
            onClick={() => {
              const newDateRange = props.goPrev();
              setPeriodLabel(newDateRange.start.toLocaleDateString() + '- ' + newDateRange.end.toLocaleDateString());
            }}
          >
            {' < '}
          </Button>
          <Button
            className={`icon-next ${styles.button}`}
            onClick={() => {
              const newDateRange = props.goNext();
              setPeriodLabel(newDateRange.start.toLocaleDateString() + '- ' + newDateRange.end.toLocaleDateString());
            }}
          >
            {' > '}
          </Button>
          <strong className={`ml-12 ${styles.navigation_title}`}>
            <span>{periodLabel}</span>
          </strong>
          <Select
            className="mr-12 select-container w-130"
            classNamePrefix="select"
            options={props.options}
            isMulti={false}
            value={props.options.find(({ value }) => value === props.selectedView)}
            onChange={onViewChange}
            searchable={false}
          />
        </div>
      </div>
    </div>
  );
});
