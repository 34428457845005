import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { getPrevFrame, getCurrentFrame, getNextFrame, Duration, getFrame } from '../../utils/month-generator';
import { DateGrouping } from 'types/gql-generated';

interface Range {
  start: Date;
  end: Date;
}

type TimelineHook = [
  Range,
  (shiftSize: DateGrouping) => void,
  {
    goPrevScreen: () => void;
    goToCurrent: () => void;
    goNextScreen: () => void;
  },
];

const getDuration = (view: DateGrouping): Duration => {
  switch (view) {
    case DateGrouping.MONTH:
      return 'month';
    case DateGrouping.WORK_WEEK:
      return 'week';
    case DateGrouping.DAY:
      return 'day';
    default:
      return 'month';
  }
};

const initialRange = { start: new Date(2020, 0, 1), end: new Date(2020, 12, 1) };

const reducer = (state: Range = initialRange, action: { type: 'CHANGE_RANGE'; payload: Range }) => {
  if (action.type === 'CHANGE_RANGE') return action.payload;
  return state;
};

export const useTimeline = (range: Range, view: DateGrouping): TimelineHook => {
  const onlyWidth = useWindowWidth();
  const [timeRange, dispatch] = React.useReducer(reducer, range);
  const [stateView, setStateView] = React.useState(view);
  const [shiftSize, setShiftSize] = React.useState(0);

  React.useEffect(() => {
    setShiftSize(Math.floor(onlyWidth / 150));
    dispatch({ type: 'CHANGE_RANGE', payload: getFrame(timeRange, shiftSize, getDuration(view)) });
  }, [onlyWidth, shiftSize, view]);

  const goPrevScreen = React.useCallback(() => {
    dispatch({ type: 'CHANGE_RANGE', payload: getPrevFrame(timeRange, shiftSize, getDuration(stateView)) });
  }, [shiftSize, timeRange, stateView]);

  const goToCurrent = React.useCallback(() => {
    dispatch({ type: 'CHANGE_RANGE', payload: getCurrentFrame(shiftSize, getDuration(stateView)) });
  }, [shiftSize, stateView]);

  const goNextScreen = React.useCallback(() => {
    dispatch({ type: 'CHANGE_RANGE', payload: getNextFrame(timeRange, shiftSize, getDuration(stateView)) });
  }, [timeRange, shiftSize, stateView]);

  return [
    timeRange,
    setStateView,
    {
      goPrevScreen,
      goToCurrent,
      goNextScreen,
    },
  ];
};
