import React from 'react';

interface Props {
  className?: string;
}

export const TeamMemberIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="37.5" height="50" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect className={className} x="-7.61377" y="-3.15527" width="217.669" height="205.159" fill="#F1F5F8" />
    </svg>
  );
};
