import React, { useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { ProjectMenu } from '../../components/ProjectMenu';
import { Helmet } from 'react-helmet';

import { useAppStore } from 'store/app-store.hook';

import { PageHeader } from 'components/PageHeader';
import { ProjectTitle } from 'components/ProjectTitle';
import { ProjectNavigation } from './ProjectNavigation';
import { useParams } from 'react-router-dom';
import { QuicklinkCreate } from 'components/Quicklink/QuicklinkCreate';
import { QuicklinkPopup } from 'components/Quicklink/QuicklinkPopup';
import { ModeOptions } from 'types/select-types';

export const ProjectQuicklinksPage = () => {
  const { projectOverviewStore } = useAppStore();
  const { id, view } = useParams<{ id: string; view: string }>();

  const [viewMode, setViewMode] = useState<ModeOptions>(view as ModeOptions);

  const [showPopup, setShowPopup] = useState(false);

  return (
    <div>
      <Helmet>
        <title>Construction SaaS | Quicklinks</title>
      </Helmet>
      <PageHeader
        title={<ProjectTitle {...projectOverviewStore.title} />}
        navigation={<ProjectNavigation id={id} view={viewMode} />}
        menu={<ProjectMenu onModeChanged={setViewMode} view={viewMode} />}
      />
      <Row>
        <Col>
          {/* <QuicklinkList links={links} /> */}
          <div className="mh-custom text-center d-flex align-items-center justify-content-center pb-100">
            <QuicklinkCreate onCreate={() => setShowPopup(true)} />
          </div>
        </Col>
      </Row>
      <Modal isOpen={showPopup} toggle={() => setShowPopup(!showPopup)}>
        <QuicklinkPopup onCancel={() => setShowPopup(false)} />
      </Modal>
    </div>
  );
};
