import { ProjectsQLVariables, ProjectsQL_projects_nodes } from './gql-generated';
import { ProjectOverviewQL_projectOverview_team } from './gql-generated/ProjectOverviewQL';

export type LoadProjectsParams = ProjectsQLVariables['input'];

export enum ProjectsView {
  TIME = 'time',
  DOLLARS = 'dollars',
}

export type ProjectNode = ProjectsQL_projects_nodes;

export interface ProjectListItem {
  id?: number;
  name: string;
  agency?: string;
  client?: string;
  status?: string;
  used: number;
  usedH: number;
  budget: number;
  budgetH: number;
  type?: string;
  value: number;
  remaining: number;
  remainingH: number;
  percentage: number;
  costs: number;
  costsH: number;
}

export type Project = Pick<
  ProjectOverviewQL_projectOverview_team,
  | 'id'
  | 'costTaasHours'
  | 'costUsd'
  | 'hours'
  | 'usedTaasHours'
  | 'usedUsd'
  | 'budgetTaasHours'
  | 'budgetUsd'
  | 'fulfilmentTaasHours'
  | 'fulfilmentUsd'
  | 'remainingTaasHours'
  | 'remainingUsd'
>;

export interface ProjectTeam extends Project, ProjectOverviewQL_projectOverview_team {}

interface ProjectClientOverviewQL_projectClient_team {
  projectName: string;
}

export interface ProjectClient extends Project, ProjectClientOverviewQL_projectClient_team {}

interface ProjectAgencyOverviewQL_projectAgency_team {
  company: string;
}

export interface ProjectAgency extends Project, ProjectAgencyOverviewQL_projectAgency_team {}

export interface QuicklinkCreate {
  title: string;
  url: string;
}

export interface CreditCategoryCreate {
  creditCategory: string;
}
