import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectMenu } from '../../components/ProjectMenu';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { TableToolbar } from 'components/TableToolbar';
import { ModeOptions, PERIOD_SELECT_OPTIONS } from 'types/select-types';
import { ProjectNavigation } from './ProjectNavigation';
import moment from 'moment';
import { PageHeader } from 'components/PageHeader';
import { ProjectTitle } from 'components/ProjectTitle';

export const ProjectInvoicesPage: React.FC = observer(() => {
  const { projectOverviewStore, timelineStore } = useAppStore();
  const { id, view } = useParams<{ id: string; view: string }>();
  const [viewMode, setViewMode] = useState<ModeOptions>(view as ModeOptions);

  React.useEffect(() => {
    projectOverviewStore.loadProject({
      id,
      from: moment(timelineStore.period.start).format('YYYY-MM-DD'),
      to: moment(timelineStore.period.end).format('YYYY-MM-DD'),
    });
  }, [id, projectOverviewStore, timelineStore.period]);

  return (
    <div>
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <PageHeader
        title={<ProjectTitle {...projectOverviewStore.title} />}
        navigation={<ProjectNavigation id={id} view={viewMode} />}
        menu={<ProjectMenu onModeChanged={setViewMode} view={viewMode} />}
        toolbar={<TableToolbar {...timelineStore.toolbar} options={PERIOD_SELECT_OPTIONS} />}
      />
    </div>
  );
});
