import { PeriodRange } from 'types/timeline-types';
import queryString from 'query-string';
import moment from 'moment';
import { DateGrouping } from 'types/gql-generated';

type Period = DateGrouping.MONTH | DateGrouping.WORK_WEEK | DateGrouping.DAY;

export const convertUsaToIso = (date: string): string => moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD');
export const convertIsoToUsa = (date: string): string => moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');

export const getViewFromUrl = (str: string): DateGrouping => {
  const view = queryString.parse(str).view as string;
  if (view === DateGrouping.MONTH) return DateGrouping.MONTH;
  if (view === DateGrouping.WORK_WEEK) return DateGrouping.WORK_WEEK;
  if (view === DateGrouping.DAY) return DateGrouping.DAY;
  return DateGrouping.MONTH;
};

export const getPeriodFromUrl = (str: string): PeriodRange | null => {
  const { start, end } = queryString.parse(str);
  if (!start || !end) return null;
  return {
    start: moment(start as string).toDate(),
    end: moment(end as string).toDate(),
  };
};

export const getUrlParamsFromRange = ({ start, end }: PeriodRange): string => {
  return `start=${moment(start).format('YYYY-MM-DD')}&end=${moment(end).format('YYYY-MM-DD')}`;
};

export const getPeriodByView = (period: Period): PeriodRange => {
  switch (period) {
    case DateGrouping.MONTH: {
      return {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('month').toDate(),
      };
    }
    case DateGrouping.WORK_WEEK: {
      return {
        start: moment().startOf('week').toDate(),
        end: moment().endOf('week').toDate(),
      };
    }
    case DateGrouping.DAY: {
      return {
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
      };
    }
  }
};
