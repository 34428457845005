import React from 'react';
import { Helmet } from 'react-helmet';

export const MyExpensesPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Expenses | My Expenses</title>
      </Helmet>
    </>
  );
};
