import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Category } from 'types';

interface CellModalProps {
  onClose: () => void;
  showModal: boolean;
  data: Category;
}

export default function CellModal({ onClose, showModal, data }: CellModalProps) {
  return (
    <Modal isOpen={showModal}>
      <ModalHeader toggle={onClose}>{data.name}</ModalHeader>
      <ModalBody>
        <p>Costs: {data.costs['Jan 2021']}</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClose}>
          Do Something
        </Button>{' '}
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
