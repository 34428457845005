import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';

export const DashboardPage: React.FC = observer(() => {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
    </>
  );
});
