import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { ProjectTitle } from '../../components/ProjectTitle';
import { ProjectMenu } from '../../components/ProjectMenu';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { DateTable } from 'components/DateTable';
import { Helmet } from 'react-helmet';
import { ProjectNavigation } from './ProjectNavigation';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { TableToolbar } from 'components/TableToolbar';
import { ModeOptions, PERIOD_SELECT_OPTIONS } from 'types/select-types';
import { SpreadsheetTable } from 'components/SpreadsheetTable';
import { PageHeader } from 'components/PageHeader/PageHeader';

export const ProjectDetailsPage: React.FC = observer(() => {
  const { projectDetailsStore, projectOverviewStore, timelineStore } = useAppStore();
  const { id, view } = useParams<{ id: string; view?: string }>();
  const editMode = false;
  const [viewMode, setViewMode] = useState<ModeOptions>(view as ModeOptions);

  React.useEffect(() => {
    projectDetailsStore.loadProjectDetails({
      id,
      from: moment(timelineStore.period.start).format('YYYY-MM-DD'),
      to: moment(timelineStore.period.end).add(8, 'months').format('YYYY-MM-DD'),
      grouping: timelineStore.selectedView,
    });
  }, [id, projectDetailsStore, timelineStore.period, timelineStore.selectedView]);

  return (
    <div>
      <Helmet>
        <title>Projects | Details</title>
      </Helmet>
      <PageHeader
        title={<ProjectTitle {...projectOverviewStore.title} />}
        navigation={<ProjectNavigation id={id} view={viewMode} />}
        menu={<ProjectMenu onModeChanged={setViewMode} view={viewMode} />}
        toolbar={<TableToolbar {...timelineStore.toolbar} options={PERIOD_SELECT_OPTIONS} />}
      />
      <Row>
        {editMode ? (
          <SpreadsheetTable view={timelineStore.selectedView} />
        ) : (
          <DateTable
            view={timelineStore.selectedView}
            teamData={projectDetailsStore.teamDetails.combinedTeamDetails}
            creditsOverview={projectDetailsStore.creditsOverview}
            startingBalanceOverview={projectDetailsStore.startingBalanceOverview}
            endBalanceOverview={projectDetailsStore.endBalanceOverview}
            billableExpensesOverview={projectDetailsStore.billableExpensesOverview}
            netIncomeOverview={projectDetailsStore.netIncomeOverview}
            billableExpensesDetails={projectDetailsStore.expensesDetails}
          />
        )}
      </Row>
    </div>
  );
});
