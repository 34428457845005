import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { validators, validate } from '../../../utils/form-utils';
import { ResetPasswordRequest } from '../../../types/auth-types';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { NavLink } from 'react-router-dom';
import { authRoutes } from '../../../routing';

interface FormValues {
  password: string;
  repeatPassword: string;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      password: validators.password,
      repeatPassword: validators.repeatPassword,
    })
    .required();

const initialValues = {
  password: '',
  repeatPassword: '',
};

interface ResetPasswordFormProps {
  error?: string;
  onResetPassword: (params: ResetPasswordRequest) => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onResetPassword, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onResetPassword,
    validate: validate(validationSchema),
  });
  const { values, errors, touched, isValid } = formik;

  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-card">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>Reset your Password</h1>
            <p>Give us your email, and we’ll send you a link to reset your password.</p>
          </div>
          <Form onSubmit={formik.handleSubmit} noValidate name="simpleForm">
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.password },
                { 'input-invalid': touched.password && !!errors.password },
                { 'input-touched': touched.password },
                { 'input-valid': touched.password && !errors.password },
              )}
            >
              <label htmlFor="password">New password</label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="New password"
                valid={touched.password && !errors.password}
                invalid={touched.password && !!errors.password}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.password}
              />
              <FormFeedback>{errors.password}</FormFeedback>
            </FormGroup>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.repeatPassword },
                {
                  'input-invalid': touched.repeatPassword && !!errors.repeatPassword,
                },
                { 'input-touched': touched.repeatPassword },
                {
                  'input-valid': touched.repeatPassword && !errors.repeatPassword,
                },
              )}
            >
              <label htmlFor="repeatPassword">Repeat password</label>
              <Input
                type="password"
                name="repeatPassword"
                id="repeatPassword"
                placeholder="Repeat password"
                valid={touched.repeatPassword && !errors.repeatPassword}
                invalid={touched.repeatPassword && !!errors.repeatPassword}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.repeatPassword}
              />
              <FormFeedback>{errors.repeatPassword}</FormFeedback>
            </FormGroup>
            {error && <div className="text-center text-danger mb-1">{error}</div>}
            <FormGroup className="actions">
              <Button
                type="submit"
                color="primary"
                className="btn-block"
                disabled={!isValid}
                onClick={formik.handleSubmit}
              >
                Reset password
              </Button>
            </FormGroup>
            <div className="d-flex align-items-center justify-content-center">
              <span className="mr-1">Remember your password?</span>
              <NavLink className="text-center" to={authRoutes.LOGIN}>
                Login
              </NavLink>
            </div>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right bg-lightblue d-none d-lg-block"></div>
    </div>
  );
};
