import { HttpConnector } from 'store/http-connector';
import { GQLConnector } from 'store/qgl-connector';

export class ServicesStore {
  public qglConnector: GQLConnector;
  public httpConnector = HttpConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
  }
}
