import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient, { MutationOptions, QueryOptions, ApolloError } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { FetchResult, ApolloLink } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';
import { API_URI } from '../config';
import { readTokenToLocalStorage } from 'utils/local-storage.utils';
import { ServerError } from 'types/error.types';

const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
const cleanupResponse = (res: Object) => JSON.parse(JSON.stringify(res), omitTypename);
const getLink = (options: { uri: string; headers?: { [key: string]: string } }) =>
  ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink(options),
    ApolloLink.from([
      new ApolloLink((operation, forward) => {
        if (operation.variables) {
          operation.variables = cleanupResponse(operation.variables);
        }
        return forward(operation).map((data) => {
          return data;
        });
      }),
      new HttpLink(options),
    ]),
  );

export class GQLConnector {
  private getError = (errors: ReadonlyArray<GraphQLError>): ServerError => {
    const id = errors[0].extensions?.id;
    const status = errors[0].extensions?.status;
    const errorCode = errors[0].extensions?.errorCode;
    const data = errors[0].extensions?.data;
    const message = Array.isArray(data) ? data.join('\n') : data;
    return new ServerError(id, status, errorCode, message);
  };

  private handleError = ({ graphQLErrors, networkError }: ApolloError) => {
    if (networkError) throw networkError;
    throw this.getError(graphQLErrors);
  };

  private handleResponse = <T>(result: FetchResult<T>): T => {
    if (result.data) return result.data;
    if (result.errors) throw this.getError(result.errors);
    throw new Error('ERROR: Empty response');
  };

  mutate = async <T>(mutation: MutationOptions<T>): Promise<T> => {
    const client = await this.createHttpClient();
    return client.mutate<T>(mutation).then(this.handleResponse, this.handleError);
  };

  query = async <T, O>(query: QueryOptions<O>): Promise<T> => {
    const client = await this.createHttpClient();
    return client
      .query({ ...query, fetchPolicy: 'no-cache', errorPolicy: 'all' })
      .then(this.handleResponse, this.handleError)
      .then(cleanupResponse);
  };

  // getFreshToken = async (): Promise<string | null> => {
  //   const tokens = readTokenToLocalStorage();
  //   const accessToken = tokens?.token;
  //   const refreshToken = tokens?.refreshToken || null;
  //   if (!accessToken) return null;
  //   const { exp } = jwt_decode(accessToken);
  //   if (exp * 1000 >= Date.now()) return accessToken;
  //   return saveTokenToLocalStorage(await this.updateTokens(refreshToken)).refreshToken;
  // };

  private async createHttpClient() {
    const tokens = readTokenToLocalStorage();
    const accessToken = tokens?.token;
    return new ApolloClient<any>({
      cache: new InMemoryCache(),
      link: getLink({
        uri: `${API_URI}/graphql`,
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      }),
    });
  }
}
