import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from '../components/containers';
import { AuthRoutes } from '../pages/Auth';
import {
  getRoutesValues,
  authRoutes,
  projectsRoutes,
  dashboardRoutes,
  timeRoutes,
  companiesRoutes,
  contactsRoutes,
  expensesRoutes,
  referralsRoutes,
  billingRoutes,
  documentsRoutes,
  globalSettingsRoutes,
} from './routes';
import { ProductsRoutes } from '../pages/ProjectsPage';
import { PrivateRoute } from './PrivateRoute';
import { DashboardPage } from 'pages/DashboardPage';
import { TimeRoutes } from 'pages/TimePage';
import { CompaniesRoutes } from 'pages/CompaniesPage';
import { ContactsRoutes } from 'pages/ContactsPage';
import { ExpensesRoutes } from 'pages/ExpensesPage';
import { ReferralsRoutes } from 'pages/ReferralsPage';
import { BillingRoutes } from 'pages/BillingPage';
import { DocumentsRoutes } from 'pages/DocumentsPage';
import { GlobalSettingsRoutes } from '../pages/GlobalSettingsPage/GlobalSettingsRoutes';

export const AppRouter = () => {
  return (
    <Switch>
      <Route exact path={Object.values(authRoutes)} component={AuthRoutes} />
      <PrivateRoute
        path={[dashboardRoutes.DASHBOARD, '/']}
        exact
        render={(props) => (
          <Layout {...props}>
            <DashboardPage />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(timeRoutes)}
        exact
        render={(props) => (
          <Layout {...props}>
            <TimeRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(companiesRoutes)}
        exact
        render={(props) => (
          <Layout {...props}>
            <CompaniesRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(contactsRoutes)}
        exact
        render={(props) => (
          <Layout {...props}>
            <ContactsRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(projectsRoutes)}
        render={(props) => (
          <Layout {...props}>
            <ProductsRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(expensesRoutes)}
        render={(props) => (
          <Layout {...props}>
            <ExpensesRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(referralsRoutes)}
        render={(props) => (
          <Layout {...props}>
            <ReferralsRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(billingRoutes)}
        render={(props) => (
          <Layout {...props}>
            <BillingRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(documentsRoutes)}
        render={(props) => (
          <Layout {...props}>
            <DocumentsRoutes />
          </Layout>
        )}
      />
      <PrivateRoute
        path={getRoutesValues(globalSettingsRoutes)}
        render={(props) => (
          <Layout {...props}>
            <GlobalSettingsRoutes />
          </Layout>
        )}
      />
      <Route exact path="/healthz" component={() => <div>OK</div>} />
      <Route
        path="*"
        render={(props) => (
          <Layout {...props}>
            <h1>Hello 404</h1>
          </Layout>
        )}
      />
    </Switch>
  );
};
