import { computed, observable } from 'mobx';
import { Group } from '../types';
import { RouterStore } from 'mobx-react-router';
import { AuthStore } from './auth-store';
import { GQLConnector } from './qgl-connector';
import { AuthService } from './services/auth-service';
import { PermissionStore } from './permisson-store/index';
import { NavigationStore } from './navigation-store';
import { ProjectsStore } from './projects-store';
import { ProjectDetailsStore, ProjectOverviewStore } from './project-overview-store';
import { ProjectsService } from './services/projects-service';
import { TimelineStore } from './timeline-store';
import { ProjectQuickLinksStore } from './project-overview-store/project-quicklinks-store';
import { ServicesStore } from './services/services.store';
import { ToastStore } from 'store/toast-store';

export const CLIENT_MOCK: Group[] = [
  {
    name: 'Starting Balance',
    categories: [],
  },
  {
    name: 'Credits',
    categories: [
      {
        name: 'Brand New',
        costs: {
          'Jan 2021': 10.0,
          'Feb 2021': 1.0,
          'Mar 2021': 5.0,
          'Apr 2021': 7.0,
          'May 2021': 4.0,
          'Jun 2021': 8.0,
          'Jul 2021': 11.0,
          'Aug 2021': 6.0,
          'Sep 2021': 2.0,
          'Oct 2021': 3.0,
        },
      },
    ],
  },
  {
    name: 'Team',
    categories: [
      {
        name: 'Geoff W.',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Tomica L.',
        costs: {
          'Jan 2021': 50.0,
          'Feb 2021': 250.0,
          'Mar 2021': 100.0,
          'Apr 2021': 250.0,
          'May 2021': 50.0,
          'Jun 2021': 150.0,
          'Jul 2021': 350.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Shah R.',
        costs: {
          'Jan 2021': 25.0,
          'Feb 2021': 170.0,
          'Mar 2021': 120.0,
          'Apr 2021': 130.0,
          'May 2021': 450.0,
          'Jun 2021': 180.0,
          'Jul 2021': 190.0,
          'Aug 2021': 155.0,
          'Sep 2021': 152.0,
          'Oct 2021': 159.0,
        },
      },
      {
        name: 'Ethon B.',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
    ],
  },
  {
    name: 'Billable Expenses',
    categories: [
      {
        name: 'Travel',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Software',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Other - Reimbursable',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Other - Bank Service Char...',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Other - Credit Card Fees',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Meals and Entertainment',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Professional Fees',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Telephone & Internet',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
    ],
  },
  {
    name: 'Net Cash Movement',
    categories: [],
  },
  {
    name: 'End Balance',
    categories: [],
  },
];

export const GROUPS_MOCK: Group[] = [
  {
    name: 'Starting Balance',
    categories: [],
  },
  {
    name: 'Credits',
    categories: [
      {
        name: 'Brand New',
        costs: {
          'Jan 2021': 1000.0,
          'Feb 2021': 1500.0,
          'Mar 2021': 2150.0,
          'Apr 2021': 1150.0,
          'May 2021': 1560.0,
          'Jun 2021': 1350.0,
          'Jul 2021': 3150.0,
          'Aug 2021': 1950.0,
          'Sep 2021': 3150.0,
          'Oct 2021': 1750.0,
        },
      },
    ],
  },
  {
    name: 'Team',
    categories: [
      {
        name: 'Front End Developer',
        title: 'Juan E',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 1500.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'UX/ UI Designer',
        title: 'Oldemare A',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Digital Marketer',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Production Assistant',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Product Strategist',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
    ],
  },
  {
    name: 'Billable Expenses',
    categories: [
      {
        name: 'Travel',
        costs: {},
      },
      {
        name: 'Software',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Other - Reimbursable',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Other - Bank Service Char...',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Other - Credit Card Fees',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Meals and Entertainment',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Professional Fees',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
      {
        name: 'Telephone & Internet',
        costs: {
          'Jan 2021': 0.0,
          'Feb 2021': 150.0,
          'Mar 2021': 150.0,
          'Apr 2021': 150.0,
          'May 2021': 150.0,
          'Jun 2021': 150.0,
          'Jul 2021': 150.0,
          'Aug 2021': 150.0,
          'Sep 2021': 150.0,
          'Oct 2021': 150.0,
        },
      },
    ],
  },
  {
    name: 'Net Cash Movement',
    categories: [],
  },
  {
    name: 'End Balance',
    categories: [],
  },
];

export class AppStore {
  @observable private _groups = CLIENT_MOCK;
  private projectService: ProjectsService;
  @observable private gqlConnector: GQLConnector;
  @observable authStore: AuthStore;
  @observable permissionStore: PermissionStore;
  @observable navigationStore: NavigationStore;
  @observable projectsStore: ProjectsStore;
  @observable projectOverviewStore: ProjectOverviewStore;
  @observable projectQuickLinksStore: ProjectQuickLinksStore;
  @observable projectDetailsStore: ProjectDetailsStore;
  @observable timelineStore: TimelineStore;
  @observable toastStore: ToastStore;
  @observable routerStore: RouterStore;
  private servicesStore: ServicesStore;

  constructor(routerStore: RouterStore) {
    this.routerStore = routerStore;
    this.toastStore = new ToastStore();
    this.timelineStore = new TimelineStore(routerStore);
    this.gqlConnector = new GQLConnector();
    this.projectService = new ProjectsService(this.gqlConnector);

    this.servicesStore = new ServicesStore(this.gqlConnector);
    this.authStore = new AuthStore(new AuthService(this.gqlConnector), routerStore, this.toastStore);
    this.permissionStore = new PermissionStore(this.authStore);
    this.navigationStore = new NavigationStore(this.permissionStore, routerStore);
    this.projectsStore = new ProjectsStore(this.projectService, this.routerStore, this.toastStore);
    this.projectOverviewStore = new ProjectOverviewStore(this.projectService, this.routerStore, this.toastStore);
    this.projectQuickLinksStore = new ProjectQuickLinksStore(this.projectService, this.routerStore, this.toastStore);
    this.projectDetailsStore = new ProjectDetailsStore(this.projectService, this.routerStore, this.toastStore);
  }

  @computed
  get groups() {
    return this._groups;
  }

  updateGroups = (groups: Group[]) => {
    this._groups = groups;
  };
}
