/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthenticationScheme {
  EMAIL_PASSWORD = "EMAIL_PASSWORD",
  GOOGLE = "GOOGLE",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  TOKEN_CODE = "TOKEN_CODE",
}

export enum DateGrouping {
  DAY = "DAY",
  MONTH = "MONTH",
  WORK_WEEK = "WORK_WEEK",
}

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum ProjectType {
  RECURRING = "RECURRING",
  SERVICE_BLOCK = "SERVICE_BLOCK",
}

export enum Role {
  AFFILIATE = "AFFILIATE",
  AGENCY_RESELLER_ADMIN = "AGENCY_RESELLER_ADMIN",
  AGENCY_RESELLER_BILLING_CONTACT = "AGENCY_RESELLER_BILLING_CONTACT",
  AGENCY_RESELLER_OWNER = "AGENCY_RESELLER_OWNER",
  AGENCY_RESELLER_USER = "AGENCY_RESELLER_USER",
  AGENCY_TALENT_PARTNER_ADMIN = "AGENCY_TALENT_PARTNER_ADMIN",
  AGENCY_TALENT_PARTNER_BILLING_CONTACT = "AGENCY_TALENT_PARTNER_BILLING_CONTACT",
  AGENCY_TALENT_PARTNER_OWNER = "AGENCY_TALENT_PARTNER_OWNER",
  AGENCY_TALENT_PARTNER_USER = "AGENCY_TALENT_PARTNER_USER",
  CLIENT_ADMIN = "CLIENT_ADMIN",
  CLIENT_BILLING_CONTACT = "CLIENT_BILLING_CONTACT",
  CLIENT_OWNER = "CLIENT_OWNER",
  CLIENT_USER = "CLIENT_USER",
  MT_ADMIN = "MT_ADMIN",
  MT_CUSTOMER_SUPPORT = "MT_CUSTOMER_SUPPORT",
  MT_FINANCE = "MT_FINANCE",
  MT_OUTSIDE_REP = "MT_OUTSIDE_REP",
  MT_RESOURCE_OPS = "MT_RESOURCE_OPS",
  MT_SALES_ADMIN = "MT_SALES_ADMIN",
  MT_SALES_MGR = "MT_SALES_MGR",
  MT_SALES_REP = "MT_SALES_REP",
  MT_SUPER_ADMIN_OWNER = "MT_SUPER_ADMIN_OWNER",
  MT_TEAM_MEMBER = "MT_TEAM_MEMBER",
  MT_TEAM_PM = "MT_TEAM_PM",
  SALES_REP_COMPANY_ADMIN = "SALES_REP_COMPANY_ADMIN",
  SALES_REP_COMPANY_BILLING_CONTACT = "SALES_REP_COMPANY_BILLING_CONTACT",
  SALES_REP_COMPANY_OWNER = "SALES_REP_COMPANY_OWNER",
  SALES_REP_COMPANY_REP = "SALES_REP_COMPANY_REP",
  SYSTEM_LOGIN_CODE = "SYSTEM_LOGIN_CODE",
  SYSTEM_REFRESH_TOKEN = "SYSTEM_REFRESH_TOKEN",
  SYSTEM_REGISTER_PHONE = "SYSTEM_REGISTER_PHONE",
  SYSTEM_REQUEST_CODE = "SYSTEM_REQUEST_CODE",
  SYSTEM_RESET_PASSWORD = "SYSTEM_RESET_PASSWORD",
  SYSTEM_USER = "SYSTEM_USER",
  SYSTEM_VERIFY_EMAIL = "SYSTEM_VERIFY_EMAIL",
  SYSTEM_VERIFY_INVITE = "SYSTEM_VERIFY_INVITE",
  SYSTEM_VERIFY_PHONE = "SYSTEM_VERIFY_PHONE",
}

export enum SortDir {
  ASC = "ASC",
  DESC = "DESC",
}

export interface AuthenticationInput {
  scheme: AuthenticationScheme;
  emailPassword?: EmailPasswordInput | null;
  tokenCode?: TokenCodeInput | null;
  refreshToken?: string | null;
  googleCode?: string | null;
}

export interface CodeInput {
  code: string;
}

export interface EmailInput {
  email: string;
}

export interface EmailPasswordInput {
  email: string;
  password: string;
}

export interface NewPasswordInput {
  password: string;
}

export interface PhoneInput {
  countryCode: number;
  phone: string;
}

export interface ProjectDetailsInput {
  id: string;
  from: string;
  to: string;
  grouping: DateGrouping;
}

export interface ProjectOverviewInput {
  id: string;
  from: string;
  to: string;
}

export interface RegisterInput {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface SearchInput {
  limit?: number | null;
  offset?: number | null;
  sort?: SortInput[] | null;
  query?: any | null;
}

export interface SortInput {
  field: string;
  dir: SortDir;
}

export interface TokenCodeInput {
  token: string;
  code: string;
}

export interface VerifyInviteInput {
  register?: VerifyRegisterInput | null;
}

export interface VerifyRegisterInput {
  firstName: string;
  lastName: string;
  password: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
