import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';

import { CodeIcon } from '../../SvgIcons/Code';
import { validators, validate } from '../../../utils/form-utils';
import { PhoneVerifyRequest } from '../../../types/auth-types';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';

interface FormValues {
  error?: string;
  code: string;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      code: validators.code,
    })
    .required();

const initialValues = {
  code: '',
};

interface TwoStepVerificationPhoneFormProps {
  error?: string;
  onVerify: (params: PhoneVerifyRequest) => void;
  sendSms: () => void;
}

export const TwoStepVerificationPhoneForm: React.FC<TwoStepVerificationPhoneFormProps> = ({
  error,
  onVerify,
  sendSms,
}) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onVerify,
    validate: validate(validationSchema),
  });
  const { values, errors, touched, isValid } = formik;

  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-card">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>2-Step Verification</h1>
            <p>Please enter the code that we texted to you.</p>
          </div>
          <Form onSubmit={formik.handleSubmit} noValidate name="simpleForm">
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.code },
                { 'input-invalid': touched.code && !!errors.code },
                { 'input-touched': touched.code },
                { 'input-valid': touched.code && !errors.code },
              )}
            >
              <label htmlFor="code">
                <CodeIcon />
              </label>
              <Input
                type="text"
                name="code"
                id="code"
                placeholder="Enter the code"
                valid={touched.code && !errors.code}
                invalid={touched.code && !!errors.code}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.code}
              />
              <FormFeedback>{errors.code}</FormFeedback>
            </FormGroup>
            {error && <div className="text-center text-danger mb-1">{error}</div>}
            <FormGroup className="actions">
              <Button type="submit" color="primary" className="btn-block" disabled={!isValid} onClick={formik.handleSubmit}>
                Validate
              </Button>
            </FormGroup>
            <FormGroup>
              <Button type="button" color="primary" className="btn-block" onClick={sendSms}>
                Resend
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right bg-lightblue d-none d-lg-block"></div>
    </div>
  );
};
