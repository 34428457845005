import React from 'react';
import DataSheet from 'react-datasheet';
import { useTimeline } from '../DateTable/use-timeline';
import {
  getTimeframe,
  getDayTimeframe,
  getRangeFromUrl,
  currentYearRange,
} from '../../utils/month-generator';
import { groupsToGrid, gridToGroups } from '../../utils/mocked-data';
import { useAppStore } from '../../store/app-store.hook';
import { observer } from 'mobx-react-lite';

import styles from '../DateTable/DateTable.module.scss';
import './SpreadsheetTable.module.scss';
import './SpreadsheetTable.scss';
import { useLocation } from 'react-router-dom';
import { DateGrouping } from 'types/gql-generated';

interface Col {
  width: number;
  label: string;
}

interface HeaderProps {
  col: Col;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { col } = props;
  return (
    <th className="cell read-only" style={{ width: col.width }}>
      {col.label}
    </th>
  );
};

interface SheetHeaderProps {
  className?: string;
  columns: Col[];
}

const SheetRenderer: React.FC<SheetHeaderProps> = ({ className, columns, children }) => {
  return (
    <table className={className}>
      <thead>
        <tr>
          <th className="cell read-only row-handle" />
          <th className="cell read-only row-handle" />
          {columns.map((col, index) => (
            <Header key={`${col.label}_${index}`} col={col} />
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

const RowRenderer: React.FC = ({ children }) => {
  return (
    <tr>
      <td className="cell read-only row-handle" />
      {children}
    </tr>
  );
};

interface Props {
  view: DateGrouping;
}

export const SpreadsheetTable: React.FC<Props> = observer(({ view }) => {
  const { search } = useLocation();
  const { groups, updateGroups } = useAppStore();
  const [periodRange] = useTimeline(getRangeFromUrl(search, currentYearRange), view);
  const monthTimeframe = getTimeframe(periodRange);
  const weekTimeframe = getTimeframe(periodRange).flatMap((frame) => frame.weeks.map((week) => week));
  const dayTimeframe = getDayTimeframe(periodRange);

  const timeframe = (function () {
    if (view === DateGrouping.MONTH) {
      return monthTimeframe.map(({ month }) => month);
    }
    if (view === DateGrouping.WORK_WEEK) {
      return weekTimeframe;
    }
    if (view === DateGrouping.DAY) {
      return dayTimeframe.flatMap((frame) => frame.days.map((day) => day));
    }
    return [];
  })();

  const columns = timeframe.map((label) => ({ label }));

  const gridState = React.useMemo(() => {
    return groupsToGrid(
      groups,
      columns.map((m) => m.label),
    );
  }, [columns, groups]);

  const handleChanges = (changes: any) => {
    const grid = gridState.map((row: any) => [...row]);
    changes.forEach(({ cell, row, col, value }: any) => {
      if (grid[row] && grid[row][col]) {
        grid[row][col] = { ...grid[row][col], value };
      }
    });
    const newGroups = gridToGroups(
      grid,
      timeframe.map((v) => v),
    );
    updateGroups(newGroups);
  };

  const renderSheet = (props: any) => {
    return <SheetRenderer columns={columns} {...props} />;
  };

  const renderRow = (props: any) => {
    const { row, cells, ...rest } = props;
    return <RowRenderer rowIndex={row} {...rest} />;
  };

  return (
    <div>
      <DataSheet
        data={gridState.map((a, i) => a.map((cell, j) => Object.assign(cell, { key: `${i}-${j}`, readOnly: j === 0 })))}
        valueRenderer={(cell) => cell.value}
        sheetRenderer={renderSheet}
        rowRenderer={renderRow}
        onCellsChanged={handleChanges}
        className={`${styles.fix_table} ${styles.date_table}`}
      />
    </div>
  );
});
