import React, { MouseEventHandler, useState } from 'react';
import { Button } from 'reactstrap';
import cn from 'classnames';
import { Category } from '../../types';
import styles from './GroupTable.module.scss';
import { ProgressRange } from 'components/ProgressRange';
import { TeamMemberIcon } from '../SvgIcons/TeamMember';

interface Props {
  name: string;
  expand: boolean;
  categories: Category[];
  creditsOverview: any;
  billableExpensesOverview: any;
  startingBalanceOverview: any;
  endBalanceOverview: any;
  netIncomeOverview: any;
  timeframe: string[];
  selectedIndex?: null | number;
  onClick: (selectedIndex: null | number) => void;
  onCellClick: (category: Category) => void;
  onAddButtonClick: (openModal: boolean) => void;
  showGroupModal: (showModal: boolean) => void;
  setGroupName: (name: string) => void;
}

export const GroupTable: React.FC<Props> = ({
  name,
  categories,
  creditsOverview,
  billableExpensesOverview,
  startingBalanceOverview,
  endBalanceOverview,
  netIncomeOverview,
  timeframe,
  selectedIndex = null,
  expand = true,
  onClick,
  onCellClick,
  onAddButtonClick,
  setGroupName,
}) => {
  const [isOpen, setIsOpen] = useState(expand);
  const [expandColumn, setExpandColumn] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onClickHandler: MouseEventHandler<any> = (e) => {
    const index = (e.target as HTMLElement).getAttribute('data-index');
    onClick(index ? Number(index) : null);
  };

  return (
    <>
      <div className={styles.group_table_holder}>
        <div className={styles.group_table_inner}>
          <table className={styles.group_table} onClick={onClickHandler}>
            <thead>
              <tr title="Click to open/close the details" className={cn({ [styles.show]: isOpen })}>
                <th onClick={expand ? toggle : undefined}>
                  {expand && <i className={styles.icon_open} />}
                  {name}
                </th>
                {timeframe.map((frame, i) => (
                  <th
                    key={`${name}_${i}`}
                    data-index={i}
                    className={cn({
                      [styles.selected]: selectedIndex === i,
                    })}
                    onClick={() => {
                      setGroupName(name);
                      setExpandColumn(true);
                    }}
                  >
                    <div className={styles.cell_data} data-index={i}>
                      {name === 'Credits' && creditsOverview && creditsOverview[frame]}
                      {name === 'Starting Balance' && startingBalanceOverview && startingBalanceOverview[frame]}
                      {name === 'Net Cash Movement' && netIncomeOverview && netIncomeOverview[frame]}
                      {name === 'End Balance' && endBalanceOverview && endBalanceOverview[frame]}
                      {name === 'Billable Expenses' && billableExpensesOverview && billableExpensesOverview[frame]}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            {isOpen && (
              <tbody>
                {categories.map((category, index) => {
                  return (
                    <tr key={`${category.name}_${index}`}>
                      {name === 'Team' ? (
                        <td>
                          <div className={styles.flex}>
                            <TeamMemberIcon />
                            <div>
                              <span className={styles.bold}>{category.title}</span>
                              <p className={styles.name}>{category.name}</p>
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td>
                          {category.name} <span className={styles.title}>{category.title}</span>
                        </td>
                      )}
                      {timeframe.map((frame, i) => (
                        <td
                          key={`${category.name}_${i}`}
                          className={cn({
                            [styles.selected]: selectedIndex === i,
                          })}
                          onClick={() => onCellClick(category)}
                        >
                          <i className={styles.icon_add} onClick={() => onCellClick(category)} />
                          <div>
                            <div className={styles.cell_data}>{category.costs && category.costs[frame]}</div>
                            <p className={styles.cell_data_hours}>{category.hours && category.hours[frame]}</p>
                          </div>
                          <div className={styles.cell_progress}>
                            <ProgressRange value={50} valueSpent={500} valueMax={1000} />
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
                <tr
                  onClick={() => {
                    setGroupName(name);
                    onAddButtonClick(true);
                  }}
                >
                  <td>
                    {name.includes('Credits') && (
                      <Button color="inline" style={{ padding: '0.5rem' }}>
                        <i className="icon-add"></i>Add Credit Category
                      </Button>
                    )}
                    {name.includes('Team') && (
                      <Button color="inline" style={{ padding: '0.5rem' }}>
                        <i className="icon-add"></i>Add Role
                      </Button>
                    )}
                    {name.includes('Expenses') && (
                      <Button color="inline" style={{ padding: '0.5rem' }}>
                        <i className="icon-add"></i>Add Expense Category
                      </Button>
                    )}
                  </td>
                  {timeframe.map((frame, i) => (
                    <td key={`${frame}_${i}`}></td>
                  ))}
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};
