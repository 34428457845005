import React, { useState } from 'react';
import cn from 'classnames';
import { GroupTable } from '../GroupTable';
import './DateTable.scss';
import styles from './DateTable.module.scss';
import { useTimeline } from './use-timeline';
import {
  getTimeframe,
  getDayTimeframe,
  getRangeFromUrl,
  currentYearRange,
  getFrame,
  Duration,
} from '../../utils/month-generator';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../store/app-store.hook';

import { useLocation } from 'react-router-dom';
import { DateGrouping } from 'types/gql-generated';
import CellModal from './CellModal';
import GroupModal from './GroupModal';
import AddModal from './AddModal';
import { Category } from 'types';
import { useWindowWidth } from '@react-hook/window-size';

interface Props {
  view: DateGrouping;
  teamData: any;
  creditsOverview: any;
  startingBalanceOverview: any;
  endBalanceOverview: any;
  billableExpensesOverview: any;
  netIncomeOverview: any;
  billableExpensesDetails: any;
}

export const DateTable: React.FC<Props> = observer(
  ({
    view,
    teamData,
    creditsOverview,
    billableExpensesOverview,
    endBalanceOverview,
    netIncomeOverview,
    startingBalanceOverview,
    billableExpensesDetails,
  }) => {
    const onlyWidth = useWindowWidth();
    const { groups } = useAppStore();
    const { search } = useLocation();
    const [selectedIndex, setSelectedIndex] = React.useState<null | number>(null);
    const [showCellModal, setShowCellModal] = useState<boolean>(false);
    const [cellModalData, setCellModalData] = useState<Category>({
      name: '',
      title: '',
      costs: { ['Mar 2021']: 6868 },
      hours: { ['Mar 2021']: 57 },
    });
    const [showGroupModal, setShowGroupModal] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [groupName, setGroupName] = useState('');
    const [shiftSize] = React.useState(Math.floor(onlyWidth / 150));
    const periodRange = getFrame(getRangeFromUrl(search, currentYearRange), shiftSize, getDuration(view));

    const onColumnClick = React.useCallback((index: number | null) => {
      setSelectedIndex(index);
    }, []);

    /** Will show a modal with the selected cell information. */
    const onCellClick = (category: Category) => {
      setCellModalData(category);
      setShowCellModal(true);
    };

    const getSecondaryHeader = () => {
      switch (view) {
        case DateGrouping.WORK_WEEK:
          return getTimeframe(periodRange).flatMap((frame) => [
            frame.month,
            ...Array(frame.weeks.length).fill('').slice(1),
          ]);
        case DateGrouping.DAY:
          return getDayTimeframe(periodRange).flatMap((frame) => [
            frame.week,
            ...Array(frame.days.length).fill('').slice(1),
          ]);
      }
      return [];
    };

    const getMainHeader = () => {
      switch (view) {
        case DateGrouping.MONTH:
          return getTimeframe(periodRange).map((frame) => frame.month);
        case DateGrouping.WORK_WEEK:
          return getTimeframe(periodRange).flatMap((frame) => frame.weeks.map((week) => week));
        case DateGrouping.DAY:
          return getDayTimeframe(periodRange).flatMap((frame) => frame.days.map((days) => days));
      }
    };

    const getTableData = () => {
      switch (view) {
        case DateGrouping.MONTH:
          return getTimeframe(periodRange).map((frame) => frame.month);
        case DateGrouping.WORK_WEEK:
          return getTimeframe(periodRange).flatMap((frame) => frame.weeks.map((week) => week));
        case DateGrouping.DAY:
          return getDayTimeframe(periodRange).flatMap((frame) => frame.days.map((days) => days));
      }
    };

    const mainHeader = getMainHeader();
    const secondaryHeader = getSecondaryHeader();
    const tableData = getTableData();
    return (
      <>
        <div className={styles.table_wrapper}>
          <div className={styles.date_table_holder}>
            <table className={styles.date_table}>
              <thead>
                {!!secondaryHeader.length && (
                  <tr className={styles.header_top}>
                    <th className={styles.extended} />
                    {secondaryHeader.map((item, i) => (
                      <th
                        key={`${item}_${i}`}
                        className={cn({
                          [styles.selected]: i === selectedIndex,
                          [styles.item_title]: item,
                          [styles.borderless]: !item,
                        })}
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                )}
                {!!mainHeader.length && (
                  <tr className={styles.header}>
                    {<th className={styles.extended} />}
                    {mainHeader.map((week, i) => (
                      <th
                        className={cn({
                          [styles.selected]: i === selectedIndex,
                        })}
                        key={`th_${i}`}
                      >
                        <div>{week}</div>
                      </th>
                    ))}
                  </tr>
                )}
              </thead>
            </table>
          </div>
          {groups.map((g) => {
            const loadedDetails =
              g.name === 'Team' ? teamData : g.name === 'Billable Expenses' ? billableExpensesDetails : g.categories;
            return (
              <GroupTable
                {...{
                  ...g,
                  categories: loadedDetails,
                }}
                creditsOverview={creditsOverview}
                billableExpensesOverview={billableExpensesOverview}
                startingBalanceOverview={startingBalanceOverview}
                endBalanceOverview={endBalanceOverview}
                netIncomeOverview={netIncomeOverview}
                key={g.name}
                timeframe={tableData}
                onClick={onColumnClick}
                onCellClick={onCellClick}
                onAddButtonClick={setShowAddModal}
                setGroupName={setGroupName}
                showGroupModal={setShowGroupModal}
                selectedIndex={selectedIndex}
                expand={!!g.categories.length}
              />
            );
          })}
        </div>
        {showCellModal && (
          <CellModal onClose={() => setShowCellModal(false)} showModal={showCellModal} data={cellModalData} />
        )}
        {showGroupModal && (
          <GroupModal onClose={() => setShowGroupModal(false)} showModal={showGroupModal} name={groupName} />
        )}
        {showAddModal && <AddModal onClose={() => setShowAddModal(false)} showModal={showAddModal} name={groupName} />}
      </>
    );
  },
);

const getDuration = (view: DateGrouping): Duration => {
  switch (view) {
    case DateGrouping.MONTH:
      return 'month';
    case DateGrouping.WORK_WEEK:
      return 'week';
    case DateGrouping.DAY:
      return 'day';
    default:
      return 'month';
  }
};
