import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { LoginRequest } from '../../../types/auth-types';
import { NavLink } from 'react-router-dom';
import { GoogleIcon } from '../../../components/SvgIcons/Google';
import { authRoutes } from '../../../routing';
import { validators, validate } from '../../../utils/form-utils';
import { googleLoginUrl } from 'utils/google-login';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      email: validators.email,
      password: validators.password,
    })
    .required();

const initialValues = {
  email: '',
  password: '',
};

interface LoginInFormProps {
  error?: string;
  onLogin: (params: LoginRequest) => void;
}

export const LoginInForm: React.FC<LoginInFormProps> = ({ onLogin, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onLogin,
    validate: validate(validationSchema),
  });
  const { values, errors, touched, isValid } = formik;
  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        <div className="auth-card">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>Sign into your account</h1>
            <p>
              Need a MindTrust account?{' '}
              <NavLink className="text-center" to={authRoutes.SING_UP}>
                Create an account
              </NavLink>
            </p>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.email },
                { 'input-invalid': touched.email && !!errors.email },
                { 'input-touched': touched.email },
                { 'input-valid': touched.email && !errors.email },
              )}
            >
              <label htmlFor="email">Email</label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                autoComplete="email"
                valid={touched.email && !errors.email}
                invalid={touched.email && !!errors.email}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.email}
              />
              <i className="glyphicon glyphicon-user form-control-feedback" />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.password },
                {
                  'input-invalid': touched.password && !!errors.password,
                },
                { 'input-touched': touched.password },
                { 'input-valid': touched.password && !errors.password },
              )}
            >
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Your password"
                valid={touched.password && !errors.password}
                invalid={touched.password && !!errors.password}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.password}
              />
              <FormFeedback>{errors.password}</FormFeedback>
            </FormGroup>
            <div className="form-bar text-right">
              {/* <span className="mr-1">Forgot your password?</span> */}
              <NavLink to={authRoutes.FORGOT_PASSWORD}>Forgot Password?</NavLink>
            </div>
            <div className="auth-actions">
              {error && <div className="text-center text-danger mb-1">{error}</div>}
              <FormGroup className="d-flex justify-content-center">
                <Button className="btn-block" type="submit" color="primary" disabled={!isValid} onClick={formik.handleSubmit}>
                  Login
                </Button>
              </FormGroup>
            </div>
            <span className="label-or">or</span>
            <a className="login-google text-center" href={googleLoginUrl}>
              <GoogleIcon />
              <span>Sign in with Google</span>
            </a>
          </Form>
        </div>
      </div>
      <div className="auth-col auth-right bg-lightblue d-none d-lg-block"></div>
    </div>
  );
};
