import { DateGrouping, Role } from './gql-generated';
import { MT_ROLES, SALES_ROLES, CLIENT_ROLES, AGENCY_ROLES } from './auth-types';
import * as Moment from 'moment';
import * as MomentRange from 'moment-range';
import { TimezoneOption, TIME_ZONE_OPTIONS } from 'utils/timezone-utils';
import { LANGUAGES } from 'utils/languages-utils';
const countryTelephoneData = require('country-telephone-data');
const states = require('usa-states');

const moment = MomentRange.extendMoment(Moment);

export const getSelectOption = <T>(value: T, options: SelectOption<T>[]): SelectOption<T> | undefined =>
  options.find((o) => o.value === value);

export interface SelectOption<T> {
  label: string;
  value: T;
}

export const PERIOD_SELECT_OPTIONS: SelectOption<DateGrouping>[] = [
  { label: 'Month', value: DateGrouping.MONTH },
  { label: 'Week', value: DateGrouping.WORK_WEEK },
  { label: 'Day', value: DateGrouping.DAY },
];

const mapRolesToOption = (roles: Role[]) =>
  roles.map((r) => ({
    label: r,
    value: r,
  }));

export const USER_ROLES_SELECT_OPTIONS: SelectOption<Role>[] = [
  ...mapRolesToOption(MT_ROLES),
  ...mapRolesToOption(SALES_ROLES),
  ...mapRolesToOption(AGENCY_ROLES),
  ...mapRolesToOption(CLIENT_ROLES),
];

export const COUNTRIES_SELECT_OPTIONS: SelectOption<string>[] = [
  { label: 'Select country', value: '' },
  ...countryTelephoneData.allCountries.map(({ name, iso2 }: { name: string; iso2: string }) => ({
    label: name,
    value: iso2,
  })),
];

export const USA_STATES_SELECT_OPTIONS: SelectOption<string>[] = new states.UsaStates().states.map(
  ({ name }: { name: string }) => ({
    label: name,
    value: name,
  }),
);

export type ModeOptions = 'dashboard' | 'budget' | 'allocation' | 'pm';

export type NavigationOptions =
  | 'Overview'
  | 'Quicklinks'
  | 'Info'
  | 'Details'
  | 'Invoices'
  | 'Roadmap'
  | 'Backlog'
  | 'Board'
  | 'Reports';

export type ActionOptions = 'Sync QB' | 'Edit' | 'Duplicate' | 'Archive' | 'Delete';

export const MODE_SELECT_OPTIONS: SelectOption<ModeOptions>[] = [
  {
    label: 'Dashboard',
    value: 'dashboard',
  },
  {
    label: 'Budget',
    value: 'budget',
  },
  {
    label: 'Allocation',
    value: 'allocation',
  },
  {
    label: 'Project Mgmt',
    value: 'pm',
  },
];

export const ACTION_SELECT_OPTIONS: SelectOption<ActionOptions>[] = [
  {
    label: 'Sync QB',
    value: 'Sync QB',
  },
  {
    label: 'Edit',
    value: 'Edit',
  },
  {
    label: 'Duplicate',
    value: 'Duplicate',
  },
  {
    label: 'Archive',
    value: 'Archive',
  },
  {
    label: 'Delete',
    value: 'Delete',
  },
];
