import React from 'react';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { MODE_SELECT_OPTIONS, ACTION_SELECT_OPTIONS, ModeOptions } from 'types/select-types';

import styles from './ProjectMenu.module.scss';

interface Props {
  onModeChanged?: (mode: ModeOptions) => void;
  view?: ModeOptions;
}

export const ProjectMenu: React.FC<Props> = ({ onModeChanged, view }) => {
  // @ts-ignore
  const defaultOptionLabel = view === 'pm' ? 'Project Mgmt' : view.charAt(0).toUpperCase() + view.slice(1);
  return (
    <div className={styles.project_menu}>
      <div className="text-nowrap ml-ch-6 d-inline-block valign-top">
        <Button color="default" style={{ padding: '0.5rem' }}>
          <i className={styles.icon_stopwatch} color="primary" />
          <span className="text-nowrap text-muted">Track Time</span>
        </Button>
        <Select
          className="select-container w-130"
          classNamePrefix="select"
          placeholder="Dashboard"
          isMulti={false}
          options={MODE_SELECT_OPTIONS}
          // @ts-ignore
          onChange={(mode) => onModeChanged(mode.value)}
          value={{
            label: defaultOptionLabel,
            value: view,
          }}
        />
        <Select
          className="select-container w-130"
          classNamePrefix="select"
          placeholder="Actions"
          isMulti={false}
          options={ACTION_SELECT_OPTIONS}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};
