import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import { data } from '../../utils/pie-data';

import styles from './Pie.module.scss';
import './Pie.scss';

interface Props {
  value: number;
  title: string;
}

export const Pie: React.FC<Props> = ({ value, title }) => {
  return (
    <>
      <div className={styles.pie_holder}>
        <em className={styles.pie_total}>
          <strong>$8,523</strong> <em>Current Spend</em> as of 8/20 20:17 PM EDT
        </em>
        <Doughnut
          data={data}
          options={{
            legend: { display: false },
            cutoutPercentage: 90,
            rotation: 2.04,
            tooltips: {
              bodyFontFamily: "'avenir', Arial, Helvetica, sans-serif",
              bodyFontSize: 14,
            },
          }}
          width={214}
          height={214}
        />{' '}
        {/* rotation: 1.55 */}
        <strong className={styles.pie_value}>{value}</strong>
      </div>
      <em className={styles.pie_title}>{title}</em>
    </>
  );
};
